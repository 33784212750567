import '../../styles/Form.scss';
import './component.style.scss';
import { ownersFormText, businessDetailsFormText } from '../../db/formHebrewText';
import RadioButton from '../RadioButton';
import Input from '../Input/Input';
import { useStore } from '../../store';
import { useState, useEffect } from 'react';
import RemoveButton from '../RemoveButton/RemoveButton';
import InFormCheckBox from '../InFormCheckBox';
import File from '../File/File';

export default function OwnersForm({ ownersArrayIndex, id }: any) {
	const [isFormOpen, setIsFormOpen] = useState(false);
	const { owners, appendOwner, removeOwner, handleChange, handleOwnerKindChange, clearOwnerValues, setOwnerFile, removeOwnerFile } = useStore().ownersFormContainerContext;
	const { validityChanged } = useStore().formsContainerContext;
	const currentForm = owners.filter((owner: any) => owner.id === id)[0];
	const isLastOne = ownersArrayIndex === owners.length - 1;
	const { errors } = currentForm;
	const deliveryAddressTaken = owners.find((owner: any) => owner.setAsDeliveryAddress === '1');
	const isNotTheMainAddress = deliveryAddressTaken && id !== deliveryAddressTaken.id;
	const showOwnerKindError = !currentForm.ownerKind && validityChanged;
	const showOwnerStatusError = !currentForm.ownerStatus && validityChanged;
	useEffect(() => {
		if (isLastOne) {
			setIsFormOpen(true);
		} else {
			setIsFormOpen(false);
		}
	}, [owners.length]);

	function toggleDisplayOwnerDetails() {
		setIsFormOpen((prev) => !prev);
	}

	const handleFileChange = (e: any, ownerId: string) => {
		const file = e.target.files[0];
		const fileType: string = e.target.name;
		setOwnerFile(ownerId, file, fileType);
	};

	const handleRemoveFile = (ownerId: string, fileType: string) => {
		removeOwnerFile(ownerId, fileType);
	};

	return (
		<form className="form" name={ownersArrayIndex}>
			<div className="form-top-wrapper flex column" style={{ gap: 15 }}>
				<div className="flex space-between">
					<h1 className="form__title">{ownersFormText.title}</h1>
					<div>{owners.length > 1 && !!ownersArrayIndex && <RemoveButton ariaLabel="לחץ לסגירת טופס פרטי בעלים נוסף" onClick={() => removeOwner(id)} />}</div>
				</div>
				<div className="business-details-form__top">
					<div className="flex space-between">
						<button type="button" className="reset-button underline" onClick={() => clearOwnerValues(id)}>
							אפס טופס
						</button>
						<div className="form__instructions flex column">
							<span>{businessDetailsFormText.requiredFieldsMessage}</span>
						</div>
					</div>
					<div className="owner-form-radio-button-files-wrapper flex column">
						<div className="radio-button-file-row flex">
							<div className="radio-buttons-container flex">
								<h2 id="ownerKind_title" className="form__title_small">
									סוג הבעלים :
								</h2>
								<div className="radio-buttons-wrapper flex">
									<RadioButton isRequired={true} name="ownerKind" onChange={handleOwnerKindChange(id)} labelText={'פרטי'} value={'3'} isChecked={currentForm.ownerKind === '3'} />
									<RadioButton isRequired={true} name="ownerKind" onChange={handleOwnerKindChange(id)} labelText={'חברה'} value={'1'} isChecked={currentForm.ownerKind === '1'} />
								</div>
								{!!showOwnerKindError && <span className="required">חובה לסמן את אחת האפשרויות </span>}
							</div>

							<div className="file-upload-container flex">
								{currentForm.ownerKind !== '1' ? (
									<File
										accept="image/*,application/pdf"
										onChange={(e: any) => handleFileChange(e, id)}
										isRequired={true}
										text={`תעודת זהות`}
										name={'idCard'}
										type="upload"
										isUploaded={currentForm.files && currentForm.files['idCard']?.file ? true : false}
										isLoading={false}
										removeFile={() => handleRemoveFile(id, 'idCard')}
										fileChosen={currentForm.files && currentForm.files['idCard']?.file}
										originName={currentForm.files && currentForm.files['idCard']?.name}
										validityChanged={true}
										ariaLabel={`העלה תעודת זהות`}
									/>
								) : currentForm.ownerStatus === '4' ? (
									<File
										accept="image/*,application/pdf"
										onChange={(e: any) => handleFileChange(e, id)}
										isRequired={true}
										text={`מיופה כוח`}
										name={'proxyDocument'}
										type="upload"
										isUploaded={currentForm.files && currentForm.files['proxyDocument']?.file ? true : false}
										isLoading={false}
										removeFile={() => handleRemoveFile(id, 'proxyDocument')}
										fileChosen={currentForm.files && currentForm.files['proxyDocument']?.file}
										originName={currentForm.files && currentForm.files['proxyDocument']?.name}
										validityChanged={true}
										ariaLabel={`העלה מיופה כוח`}
									/>
								) : (
									currentForm.ownerKind === '1' && (
										<File
											accept="image/*,application/pdf"
											onChange={(e: any) => handleFileChange(e, id)}
											isRequired={true}
											text={`רשם החברות`}
											name={'companyFile'}
											type="upload"
											isUploaded={currentForm.files && currentForm.files['companyFile']?.file ? true : false}
											isLoading={false}
											removeFile={() => handleRemoveFile(id, 'companyFile')}
											fileChosen={currentForm.files && currentForm.files['companyFile']?.file}
											originName={currentForm.files && currentForm.files['companyFile']?.name}
											validityChanged={true}
											ariaLabel={`העלה רשם החברות`}
										/>
									)
								)}
							</div>
						</div>

						<div className="radio-button-file-row flex">
							<div className="radio-buttons-container flex">
								<h2 id="ownerStatus_kind" className="form__title_small">
									תפקיד הבעלים :
								</h2>
								<div className="radio-buttons-wrapper flex">
									<RadioButton isRequired={true} name="ownerStatus" onChange={handleChange(id)} labelText={'בעלים'} value={'3'} isChecked={currentForm.ownerStatus === '3'} />
									<RadioButton
										isRequired={true}
										name="ownerStatus"
										onChange={handleChange(id)}
										labelText={'מנהל'}
										value={'1'}
										isChecked={currentForm.ownerStatus === '1'}
										disabled={currentForm.ownerKind === '1' || ownersArrayIndex === 0}
									/>
									<RadioButton
										isRequired={true}
										name="ownerStatus"
										onChange={handleChange(id)}
										labelText={'מיופה כוח'}
										value={'4'}
										isChecked={currentForm.ownerStatus === '4'}
										disabled={currentForm.ownerKind === '1' || ownersArrayIndex === 0}
									/>
								</div>
								{!!showOwnerStatusError && <span className="required">חובה לסמן את אחת האפשרויות </span>}

								<span className="owner-type-instructions">במידה והנך גם בעלים וגם מנהל בעסק - יש לסמן בעלים</span>
							</div>

							{currentForm.ownerStatus === '4' && (
								<div className="file-upload-container flex">
									<File
										accept="image/*,application/pdf"
										onChange={(e: any) => handleFileChange(e, id)}
										isRequired={true}
										text={`מיופה כוח`}
										name={'proxyDocument'}
										type="upload"
										isUploaded={currentForm.files && currentForm.files['proxyDocument']?.file ? true : false}
										isLoading={false}
										removeFile={() => handleRemoveFile(id, 'proxyDocument')}
										fileChosen={currentForm.files && currentForm.files['proxyDocument']?.file}
										originName={currentForm.files && currentForm.files['proxyDocument']?.name}
										validityChanged={true}
										ariaLabel={`העלה מיופה כוח`}
									/>
								</div>
							)}
							{currentForm.ownerKind === '1' && (
								<div className="file-upload-container flex">
									<File
										accept="image/*,application/pdf"
										onChange={(e: any) => handleFileChange(e, id)}
										isRequired={true}
										text={`פרוטוקול בעלי מניות`}
										name={'stocksProtocol'}
										type="upload"
										isUploaded={currentForm.files && currentForm.files['stocksProtocol']?.file ? true : false}
										isLoading={false}
										removeFile={() => handleRemoveFile(id, 'stocksProtocol')}
										fileChosen={currentForm.files && currentForm.files['stocksProtocol']?.file}
										originName={currentForm.files && currentForm.files['stocksProtocol']?.name}
										validityChanged={true}
										ariaLabel={`העלה פרוטוקול בעלי מניות`}
									/>
								</div>
							)}
							{currentForm.ownerKind === '3' && currentForm.ownerStatus === '3' && (
								<div className="file-upload-container flex">
									<File
										accept="image/*,application/pdf"
										onChange={(e: any) => handleFileChange(e, id)}
										isRequired={true}
										text={`עוסק מורשה`}
										name={'authorizedBusiness'}
										type="upload"
										isUploaded={currentForm.files && currentForm.files['authorizedBusiness']?.file ? true : false}
										isLoading={false}
										removeFile={() => handleRemoveFile(id, 'authorizedBusiness')}
										fileChosen={currentForm.files && currentForm.files['authorizedBusiness']?.file}
										originName={currentForm.files && currentForm.files['authorizedBusiness']?.name}
										validityChanged={true}
										ariaLabel={`העלה עוסק מורשה`}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="form__main flex column">
				<div className="form__container__row" style={{ gap: '40px' }}>
					<Input
						isRequired={true}
						errorMessage={errors.name}
						name="name"
						value={currentForm.name}
						onChange={handleChange(id)}
						legend={ownersFormText.privateNameOrCompany}
						type="text"
						placeholder={''}
						min={0}
						max={64}
						pattern={undefined}
					/>
					<Input
						isRequired={true}
						name="firstname"
						errorMessage={errors.firstname}
						value={currentForm.firstname}
						onChange={handleChange(id)}
						legend={ownersFormText.familyNameOrOwner}
						type="text"
						placeholder={''}
						min={0}
						max={16}
						pattern={undefined}
					/>
					<Input
						isRequired={true}
						name="ident"
						errorMessage={errors.ident}
						value={currentForm.ident}
						onChange={handleChange(id)}
						legend={ownersFormText.identifier}
						type="text"
						pattern="[0-9]*"
						min={9}
						max={12}
						placeholder={''}
					/>
				</div>
				<h2 className={`form__title_small ${isFormOpen ? '' : 'hidden'}`}>פרטי התקשרות : </h2>
				<div className={`flex ${isFormOpen ? 'form__section_show' : 'form__section'}`} style={{ gap: '30px' }}>
					<Input
						name="telephon1"
						errorMessage={errors.telephon1}
						type="text"
						value={currentForm.telephon1}
						onChange={handleChange(id)}
						legend={businessDetailsFormText.phone}
						isRequired={false}
						placeholder={''}
						min={10}
						max={12}
						pattern="[0-9]*"
					/>
					<Input
						isRequired={true}
						errorMessage={errors.telephon2}
						name="telephon2"
						value={currentForm.telephon2}
						onChange={handleChange(id)}
						legend={businessDetailsFormText.mobilePhone}
						type="text"
						placeholder={''}
						min={10}
						max={12}
						pattern="[0-9]*"
					/>
					<Input
						isRequired={true}
						value={currentForm.email}
						errorMessage={errors.email}
						onChange={handleChange(id)}
						name="email"
						legend={businessDetailsFormText.email}
						type="email"
						placeholder={''}
						min={0}
						max={50}
						pattern={undefined}
					/>
				</div>
				<h2 className={`form__title_small `}>כתובת : </h2>
				<div className={`flex ${isFormOpen ? 'form__section_show_last' : 'form__section_address'}`} style={{ gap: '30px' }}>
					<Input
						errorMessage={'זהו שדה חובה'}
						value={currentForm.adress1}
						onChange={handleChange(id)}
						type="text"
						name="adress1"
						legend={'יישוב/איזור'}
						placeholder={''}
						min={0}
						max={100}
						pattern={undefined}
					/>
					<Input
						errorMessage={'זהו שדה חובה'}
						value={currentForm.adress2}
						onChange={handleChange(id)}
						type="text"
						name="adress2"
						legend={'רחוב'}
						placeholder={''}
						min={0}
						max={24}
						pattern={undefined}
					/>
					<div className="small-adress-inputs flex" style={{ gap: '2vw', flexDirection: 'row-reverse' }}>
						<Input
							isRequired={false}
							errorMessage={errors.adress3}
							value={currentForm.adress3}
							onChange={handleChange(id)}
							type="number"
							name="adress3"
							legend={businessDetailsFormText.homeNumber}
							placeholder={''}
							min={0}
							max={0}
							pattern={undefined}
						/>
						<Input
							value={currentForm.zipcode}
							errorMessage={errors.zipcode}
							name="zipcode"
							onChange={handleChange(id)}
							legend={businessDetailsFormText.postalCode}
							type="number"
							isRequired={false}
							placeholder={''}
							min={0}
							max={0}
							pattern={undefined}
						/>
						<Input
							errorMessage={errors.post}
							name="post"
							value={currentForm.post}
							onChange={handleChange(id)}
							type="number"
							legend={businessDetailsFormText.deliveryCode}
							isRequired={false}
							placeholder={''}
							min={0}
							max={0}
							pattern={undefined}
						/>
					</div>
				</div>
			</div>
			<div className={`form__checkboxes-two  ${isNotTheMainAddress ? 'form__checkboxes-two_hidden' : ''}`}>
				{!isNotTheMainAddress && (
					<div className={`${isFormOpen ? 'visibility-shown' : 'visibility-hidden'} flex center`}>
						<InFormCheckBox
							isRequired={false}
							labelText={ownersFormText.setAsDeliveryAddressText}
							name="setAsDeliveryAddress"
							onChange={handleChange(id)}
							checkState={currentForm.setAsDeliveryAddress === '1'}
						/>
					</div>
				)}
				{isLastOne ? (
					<div style={{ display: 'flex', gap: 20 }}>
						<button type="button" className="form__add-owner" onClick={appendOwner}>
							{ownersFormText.addAnotherOwnerText}
						</button>
					</div>
				) : (
					<button type="button" className="form__add-owner " onClick={toggleDisplayOwnerDetails}>
						{isFormOpen ? 'הסתר' : 'הצג עוד...'}
					</button>
				)}
			</div>
			{owners.length > 1 && !isLastOne && <div className="form__divider"></div>}
		</form>
	);
}
