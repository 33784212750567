import { useEffect, useState } from 'react';
import mainService from '../../api/MainService';
import { Event } from '../../ts/types';
import { getFormDataInLocalStorage, setFormDataInLocalStorage } from '../../utils/localStorage';
import { FrontendPhrase } from '../../ts/interfaces';

export const useChosenProfessions = () => {
	const [queryValue, setQueryValue] = useState('');
	const [allProfessions, setAllProfessions] = useState<any[]>([]);
	const [professionsByQuery, setProfessionsByQuery] = useState<any[]>([]);
	const [phrases, setPhrases] = useState<any[]>([]);
	const [isFreeText, setIsFreeText] = useState(false);
	const [surfaceInputToDisplay, setSurfaceInputToDisplay] = useState<'agra3' | 'surface' | 'both' | 'none'>('none');
	const [link, setLink] = useState('');
	const [groups, setGroups] = useState<string[]>([]);
	const [professions, setProfessions] = useState<any[]>([]);
	const [chosenProfessions, setChosenProfessions] = useState<any[]>(localStorage.getItem('forms') as unknown as any[]);
	const [currentGroup, setCurrentGroup] = useState({
		text: '',
		id: '',
	});
	const [isRequestCabahutPrivileged, setIsRequestCabahutPrivileged] = useState<boolean>(true);
	const [thereIsOneProfessionWithoutPrivilege, setThereIsOneProfessionWithoutPrivilege] = useState<boolean>(true);
	const [advancedNegishutRequirements, setAdvancedNegishutRequirements] = useState<boolean | null>(null);

	useEffect(() => {
		const res = getFormDataInLocalStorage('forms', []);
		setChosenProfessions(res.chosenProfessions);
	}, []);

	const filterArrayByChosenProfessions = (array: any[]) => {
		const newArr = array?.map((item: any) => {
			const isChecked = chosenProfessions?.some((prof: { id: string }) => prof.id === item.id);
			return { ...item, isChecked };
		});
		return newArr;
	};

	useEffect(() => {
		const fetchPhrases = async () => {
			try {
				if (!chosenProfessions.length) return;
				setPhrases([]);
				const pharseByChosenProfessions = await mainService.getProfessionPhrases(chosenProfessions?.map((prof) => prof.profession?.trim()));

				setPhrases(pharseByChosenProfessions);
			} catch (error) {}
		};

		fetchPhrases();
	}, [chosenProfessions]);

	const addToChosenProfessions = (item: any) => {
		setChosenProfessions((prev) => (Array.isArray(prev) ? [...prev, item] : [item]));
		setFormDataInLocalStorage(chosenProfessions, 'chosenProfessions');
	};

	const getProfessionsByGroupId = async (id: string) => {
		try {
			const res = allProfessions.filter((profession) => profession.groupe === id);
			const newArr = filterArrayByChosenProfessions(res);
			setProfessions(newArr);
		} catch (err) {
			console.log(err);
		}
	};

	const filterProfessionsByGroupId = () => {
		const newArr = filterArrayByChosenProfessions(professions);
		setProfessions(newArr);
	};

	const removeFromChosenProfessions = (itemToRemove: any) => {
		setChosenProfessions(() => chosenProfessions.filter((item: { id: string }) => item.id !== itemToRemove.id));
	};

	const getGroups = async () => {
		try {
			const res = await mainService.getStaticData('groups');
			if (res) {
				setGroups(res);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateCurrentGroupText = (event: Event & { target: HTMLButtonElement }) => {
		setCurrentGroup((prev) => ({
			...prev,
			text: event.target.value,
		}));
	};

	const updateCurrentGroupId = (id: any) => {
		setCurrentGroup((prev) => ({
			...prev,
			id,
		}));
	};

	const createLink = (profession: any) => {
		const lettersProfession = profession.match(/[\u0590-\u05FF\uFB2A-\uFB4E]/g) || ''; // Filter hebrew letters
		const decimalProfession = profession.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);
		const item = decimalProfession
			.join('')
			.split('')
			.filter((char: any) => char !== '0' && char !== '.')
			.join('.');

		const group = item[0];

		setLink(`https://www.gov.il/he/departments/dynamiccollectors/add-zav-rishuy?skip=0&group_num=0${group}&name_item=${item}%20${lettersProfession}`);
	};

	const updateFreeTextState = () => {
		setIsFreeText(!isFreeText);
		if (!isFreeText) {
			setQueryValue('');
			setProfessions([]);
			setCurrentGroup({
				text: '',
				id: '',
			});
		} else {
			setProfessionsByQuery([]);
		}
	};

	const getAllProfessions = async () => {
		try {
			const res = await mainService.getStaticData('professions');

			if (res.length) {
				const newArr = filterArrayByChosenProfessions(res);
				setAllProfessions(newArr);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleQuery = (event: Event) => {
		const { value } = event.currentTarget;
		setQueryValue(value);
	};

	const getProfessionsByQuery = (value: string) => {
		const hasLength = value.length > 0;
		const chosenByKeyWord = allProfessions.filter((prof: { descrip: string }) => {
			if (hasLength) {
				if (prof.descrip !== null) return prof.descrip.includes(value);
			}
		});
		const newArr = filterArrayByChosenProfessions(chosenByKeyWord);
		setProfessionsByQuery(newArr);
	};

	const checkPrivilegeByProfession = () => {
		// בדיקה מה סוג מסלול הכבאות שהבקשה זכאית לה, תמיד לוקחים את המקרה הגרוע ביותר
		//  לא זכאי לכלום{ cabahut_ptor: 0, cabahut_maslul: 0 };
		//   זכאי תצהיר{ cabahut_ptor: 0, cabahut_maslul: 1 };
		//   זכאי פטור{ cabahut_ptor: 0, cabahut_maslul: 1 };
		//  במידה ופריט אחד זכאי תצהיר ופריט שני זכאי פטור הבקשה עצמה זכאית לתצהיר

		let hasMaslul = false;
		let hasPtor = false;
		let hasNoPrivilege = false;

		chosenProfessions?.forEach((profession) => {
			if (profession.cabahut_ptor === 0 && profession.cabahut_maslul === 0) hasNoPrivilege = true;

			if (profession.cabahut_maslul === 1) hasMaslul = true;

			if (profession.cabahut_ptor === 1) hasPtor = true;
		});
		// Set 'true' if there's at least one profession with ptor or maslul = 1
		if (hasMaslul || hasPtor) setThereIsOneProfessionWithoutPrivilege(false);

		// If there's at least one 0, 0 profession (absolute worst case), set 'false'
		if (hasNoPrivilege) setThereIsOneProfessionWithoutPrivilege(true);

		const result = { cabahut_ptor: hasPtor ? 1 : 0, cabahut_maslul: hasMaslul ? 1 : 0 };
		// Return privilege state depending on the conditions
		return result;
	};

	const checkPrivilegeByBaseCabahutQuestion = () => {
		// בדיקת שאלות בסיס של כבאות כדי לדעת האם הבקשה זכאית לפטור או תצהיר בהתאם למה שנקבע בפונקציה
		const formSummaryFromLocalStorage = localStorage.getItem('forms');
		if (!JSON.parse(formSummaryFromLocalStorage ?? '')) return false;
		const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
		const { formSummary } = forms;

		const whereIsPlacedAnswerEqualsNoneOfTheOptions = formSummary.where_is_placed === '3'; // אם התשובה היא אף אחת מהאפשרויות למיקום העסק
		const isBusinessOnEntranceFloor = formSummary.idsend === '1'; // בודק מיקום העסק במבנה
		const isBusinessUnderground = formSummary.idsend === '3'; // בודק מיקום העסק במבנה
		const isBusinessInMultiBuildingFlag = formSummary.multy_building_flag === '1'; // מבנה רב שימוש

		if (!whereIsPlacedAnswerEqualsNoneOfTheOptions || isBusinessUnderground) return false;

		if (isBusinessInMultiBuildingFlag) {
			const isProfession64 = chosenProfessions?.some((prof: { profession: string }) => prof.profession.trim() === '6.4');
			const isSurfaceGreaterThan300 = forms.businessDetails?.surface > 300;
			// If any of the conditions are true, return false
			if (isProfession64 || isSurfaceGreaterThan300 || isBusinessOnEntranceFloor) return false;
		}

		return true; //אם כל הבדיקות הבסיסיות עברו בהצלחה הבקשה זכאית למסלול כבאות בהתבסס על שאלות הכבאות הבסיסיות
	};

	const defineSurfaceInputToDisplayOnStep5 = () => {
		let displaySurface = false;
		let displayAgra3 = false;

		chosenProfessions?.forEach((profession) => {
			if (profession.cabahut_maslul || profession.cabahut_ptor) {
				switch (profession.cabahut_surface_all_or_mvune) {
					case 0:
						if (profession.cabahut_surface) displayAgra3 = true;
						break;
					case 1:
						if (profession.cabahut_colel_surface) displaySurface = true;
						displayAgra3 = true;
						break;
					case 2:
						displaySurface = true;
						displayAgra3 = true;
						break;
					default:
						displaySurface = false;
						displayAgra3 = false;
						break;
				}
			}
		});
		// Logic to set which input to display based on the cases encountered
		if (displaySurface && displayAgra3) {
			setSurfaceInputToDisplay('both'); // If both are required
		} else if (displaySurface) {
			setSurfaceInputToDisplay('surface'); // If only 'surface' is required
		} else if (displayAgra3) {
			setSurfaceInputToDisplay('agra3'); // If only 'agra3' is required
		}
	};

	const defineCabahutTypeBySurfaceCondition = () => {
		// Load and validate forms data
		const forms = JSON.parse(localStorage.getItem('forms') ?? '{}');
		if (!forms || !chosenProfessions?.length) return 'regular';
		// Get privilege checks
		const { cabahut_ptor, cabahut_maslul } = checkPrivilegeByProfession();

		// Get surface values
		const totalSurface = forms.businessDetails?.surface;
		const builtSurface = forms.businessDetails?.agra3;
		let result = 'ptor';

		// Helper function to safely compare numbers
		const safeNumberCompare = (value1: any, value2: any) => {
			const num1 = Number(value1);
			const num2 = Number(value2);
			return !isNaN(num1) && !isNaN(num2) && num1 > num2;
		};

		for (const profession of chosenProfessions) {
			let professionResult = 'regular';

			switch (profession.cabahut_surface_all_or_mvune) {
				case 0:
					if (cabahut_ptor === 1 && cabahut_maslul === 1) {
						// Check ptor condition first
						let isPtorValid = false;
						if (profession.cabahut_ptor_surface) {
							isPtorValid = safeNumberCompare(profession.cabahut_ptor_surface, builtSurface);
							if (isPtorValid) {
								professionResult = 'ptor';
							}
						}

						// Only check maslul if ptor was not valid
						if (!isPtorValid) {
							if (profession.cabahut_surface === -1 || (profession.cabahut_surface && safeNumberCompare(profession.cabahut_surface, builtSurface))) {
								professionResult = 'maslul';
							}
						}
					} else if (cabahut_ptor === 1 && cabahut_maslul === 0) {
						if (profession.cabahut_ptor_surface && safeNumberCompare(profession.cabahut_ptor_surface, builtSurface)) {
							professionResult = 'ptor';
						}
					} else if (cabahut_ptor === 0 && cabahut_maslul === 1) {
						if (profession.cabahut_surface === -1 || (profession.cabahut_surface && safeNumberCompare(profession.cabahut_surface, builtSurface))) {
							professionResult = 'maslul';
						}
					}
					break;
				case 1:
					if (profession.cabahut_colel_surface === -1 || safeNumberCompare(profession.cabahut_colel_surface, totalSurface)) {
						professionResult = 'maslul';
					}
					break;

				case 2:
					const totalSurfaceValid = profession.cabahut_colel_surface === -1 || safeNumberCompare(profession.cabahut_colel_surface, totalSurface);
					const builtSurfaceValid = profession.cabahut_surface === -1 || safeNumberCompare(profession.cabahut_surface, builtSurface);

					if (totalSurfaceValid && builtSurfaceValid) {
						professionResult = 'maslul';
					}
					break;

				default:
					professionResult = 'regular';
			}

			// Update final result based on priority
			if (professionResult === 'regular') {
				return 'regular';
			}
			if (professionResult === 'maslul') {
				result = 'maslul';
			}
		}

		return result;
	};

	const areAllPhrasesTrue = (chosenPhrasesObj: any): boolean => {
		// Filter the chosen phrases by licensing factor and skip the specific condition
		const relevantPhrases = Object.keys(chosenPhrasesObj).filter((key) => {
			const phrase = chosenPhrasesObj[key];

			// Skip if the profession is "7.8א" and dossier_column_name is "open_space_flag"
			if (phrase.profession === '7.8א' && phrase.dossier_column_name === 'open_space_flag') return false;

			// Only include phrases with licensing_factor_code of 6
			const isLicensingFactor = phrase.licensing_factor_code === 6;
			return isLicensingFactor;
		});

		// Check if all relevant phrases are true
		const allPhrasesAreTrue = relevantPhrases.every((key) => {
			const phrase = chosenPhrasesObj[key];
			const isTrue = phrase.is_true === 1;
			return isTrue;
		});

		return allPhrasesAreTrue;
	};

	const getCabahutPrivilege = () => {
		if (thereIsOneProfessionWithoutPrivilege) return false;
		const forms = JSON.parse(localStorage.getItem('forms') ?? '');
		const baseCabahtQuestionPrivileged = checkPrivilegeByBaseCabahutQuestion();
		const allProfessionMatchSurfaceCondition = defineCabahutTypeBySurfaceCondition();
		const allPhrasesAreTrue = areAllPhrasesTrue(forms.formSummary.chosenPhrases);
		console.log('baseCabahtQuestionPrivilege', baseCabahtQuestionPrivileged);
		console.log('allProfessionMatchSurfaceCondition', allProfessionMatchSurfaceCondition);
		console.log('allPhrasesAreTrue', allPhrasesAreTrue);

		if (!baseCabahtQuestionPrivileged || allProfessionMatchSurfaceCondition?.trim() == 'regular' || !allPhrasesAreTrue) return false;
		else return true;
	};

	const checkTazhirNegishut = () => {
		const chosenPhrases: FrontendPhrase[] = Object.values(JSON.parse(localStorage.getItem('forms') as string)?.formSummary?.chosenPhrases || {}) || [];
		const allNegishutPhrases = chosenPhrases?.filter((pf) => {
			return pf.licensing_factor_code === -1;
		});
		const allNegishutPhrasesAreValid = allNegishutPhrases?.every((pf) => pf.is_true === 1);
		const thereIsNegishut = chosenProfessions?.some((prof) => prof.nagish === 1) && allNegishutPhrasesAreValid;
		return { thereIsNegishut };
	};

	// לתקן בהתאם לבדיקה של חוק תכנון ובנייה
	// בהתאם לid_maslulim_34_1 or id maslulim_34_2
	// אם אחד הפריטים שווה ל3 ונפל כבאות
	// יש לשים ערך של 3 בשדה idmaslulim_34
	// בבניית התיק
	// במידה והפריט עבר כבאות יש למלא ערך של 1 במידה וקיים id_maslulim_34_1
	// id_maslulim_34_1במידה ולא קיים ערך ל
	// יש למלא את הערך של id_maslulim_34_2

	const getIdMaslulim34Value = () => {
		if (chosenProfessions?.some((profession: any) => profession.mifrat_checked_flag !== 1)) return null;
		if (!getCabahutPrivilege() && chosenProfessions?.some((profession: any) => profession.mifrat_checked_flag === 1 && (profession.idmaslulim_34_1 === 3 || profession.idmaslulim_34_2 === 3)))
			return 3;
		else if (getCabahutPrivilege() && chosenProfessions?.some((profession: any) => profession.mifrat_checked_flag === 1 && (profession.idmaslulim_34_1 === 1 || profession.idmaslulim_34_1 === 2)))
			return 1;
		else if (getCabahutPrivilege() && chosenProfessions?.some((profession: any) => profession.mifrat_checked_flag === 1 && (profession.idmaslulim_34_2 === 1 || profession.idmaslulim_34_2 === 2)))
			return 2;
	};

	// אם כבאות מאושר
	//

	function hasNonSixLicensingFactorCode() {
		return !phrases?.some((phrase: any) => phrase.licensing_factor_code !== 6);
	}

	function defineNegishutRequirements(phrasesArray: any): void {
		for (const phrase of phrasesArray) {
			const value = parseInt(phrase.value);
			const numberToCompare = parseInt(phrase.number_to_compare);
			if (value < numberToCompare) {
				setAdvancedNegishutRequirements(false);
			} else {
				setAdvancedNegishutRequirements(true);
				break;
			}
		}
	}

	return {
		phrases,
		isRequestCabahutPrivileged,
		setIsRequestCabahutPrivileged,
		checkTazhirNegishut,
		filterProfessionsByGroupId,
		getProfessionsByQuery,
		getAllProfessions,
		link,
		hasNonSixLicensingFactorCode,
		getIdMaslulim34Value,
		handleQuery,
		queryValue,
		surfaceInputToDisplay,
		groups,
		isFreeText,
		updateFreeTextState,
		getGroups,
		allProfessions,
		professions,
		currentGroup,
		chosenProfessions,
		createLink,
		professionsByQuery,
		updateCurrentGroupId,
		updateCurrentGroupText,
		addToChosenProfessions,
		getProfessionsByGroupId,
		removeFromChosenProfessions,
		advancedNegishutRequirements,
		setAdvancedNegishutRequirements,
		defineNegishutRequirements,
		checkPrivilegeByProfession,
		thereIsOneProfessionWithoutPrivilege,
		defineSurfaceInputToDisplayOnStep5,
		getCabahutPrivilege,
		defineCabahutTypeBySurfaceCondition,
	};
};
