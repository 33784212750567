import { useEffect, useRef, useState } from 'react';
import { businessDetailsFormText } from '../../db/formHebrewText';
import '../../styles/Form.scss';
import './component.style.scss';
import { Iinput } from '../../ts/interfaces';
import { useStore } from '../../store';

function validateIsraeliID(id: string): boolean {
	if (!/^\d{9}$/.test(id)) {
		return false;
	}

	const idArray = id.split('').map(Number);

	let sum = 0;

	for (let i = 0; i < 9; i++) {
		let num = idArray[i];
		if (i % 2 !== 0) {
			num *= 2;
			if (num > 9) {
				num -= 9;
			}
		}
		sum += num;
	}

	return sum % 10 === 0;
}

export default function Input({
	legend,
	name,
	value,
	isRequired,
	type,
	placeholder,
	onChange,
	errorMessage,
	min,
	max,
	pattern,
	onClick,
	onFocus,
	onBlur,
	tabIndex,
	ariaLabel,
	maxNumber,
	instructions,
}: Iinput) {
	const thisRef = useRef<HTMLInputElement>(null);
	const [error, setError] = useState('');
	const [osekState, setOsekState] = useState(true);
	const pleaseChooseFromTheList = 'אנא בחר מהרשימה';
	const { validityChanged, forms } = useStore().formsContainerContext;
	const requiredColumnNames = localStorage.getItem('requiredFields') ?? '';
	const requiredColumnsArray = requiredColumnNames.split(',');

	const isRequiredByName = () => {
		return requiredColumnsArray.includes(name);
	};
	const isRequiredByDb = isRequiredByName();

	useEffect(() => {
		// if (name === 'osek' && forms.businessDetails.type === '0') {
		// 	const osekIsValid = validateIsraeliID(value);
		// 	setOsekState(osekIsValid);
		// 	const timer = setTimeout(() => {
		// 		setOsekState(true);
		// 	}, 2000);
		// 	return () => clearTimeout(timer);
		// }
		if (forms.businessDetails.type !== '0') {
			const timer = setTimeout(() => {
				setOsekState(true);
			}, 0);

			return () => clearTimeout(timer);
		}
	}, [forms.businessDetails.type, name, value]);

	useEffect(() => {
		if (thisRef.current && validityChanged) {
			const validation = thisRef.current.validity;
			if (!value?.trim() && validityChanged && isRequired) {
				setError('זהו שדה חובה');
				return;
			}
			if (validation) {
				let newError = '';
				if (validation.valueMissing) {
					newError = 'זהו שדה חובה';
				} else if (validation.patternMismatch) {
					newError = 'אנא מלא שדה זה בפורמט המתאים';
				}
				if (validation.tooShort || validation.tooLong) {
					if (min === max) {
						newError = `יש להזין תשובה בת ${max} תווים`;
					} else {
						newError = `יש להזין תשובה בת ${min} עד ${max} תווים`;
					}
				} else if (validation.typeMismatch) {
					newError = 'קלט אינו תקין';
				} else if (errorMessage === pleaseChooseFromTheList) {
					newError = pleaseChooseFromTheList;
				} else if (validation.rangeOverflow && name === 'agra3') {
					newError = 'שטח מבונה אינו יכול לעלות על שטח כולל';
				} else if (validation.rangeOverflow) {
					newError = 'המספר שהוקלד חורג מטווח המספרים שהוגדרו';
				}
				setError(newError);
			} else {
				setError('');
			}
		}
	}, [value, errorMessage, validityChanged, min, max]);

	const handleKeyDown = (event: any) => {
		if (type === 'number' || type === 'decimal') {
			if (
				['Backspace', 'Delete', 'Tab', 'Escape', 'Enter'].indexOf(event.key) !== -1 ||
				(event.key === 'a' && (event.ctrlKey === true || event.metaKey === true)) ||
				(event.key === 'c' && (event.ctrlKey === true || event.metaKey === true)) ||
				(event.key === 'x' && (event.ctrlKey === true || event.metaKey === true)) ||
				event.key === 'Home' ||
				event.key === 'End' ||
				event.key === 'ArrowLeft' ||
				event.key === 'ArrowRight'
			)
				return;
			if (type === 'decimal' && event.key === '.') return;
			if (event.key < '0' || event.key > '9') event.preventDefault();
		} else {
			onChange(event);
		}
	};

	let inputType;
	if (name === 'ceilling_height') {
		inputType = 'text';
	} else if (type === 'decimal') {
		inputType = 'number';
	} else {
		inputType = type;
	}

	return (
		<fieldset className={`fieldset`}>
			<label aria-label={`${legend} ${error}`}>
				<span className="required">{(isRequiredByDb || isRequired) && businessDetailsFormText.requiredSign}</span>
				{legend} {legend && <span>:</span>}
			</label>
			<input
				tabIndex={tabIndex}
				ref={thisRef}
				autoComplete="off"
				onKeyDown={(event) => handleKeyDown(event)}
				placeholder={placeholder}
				className={`input ${error ? 'input_error' : ''}`}
				minLength={min}
				maxLength={max}
				max={maxNumber}
				pattern={pattern}
				name={name}
				onWheel={(e: any) => e.target.blur()}
				aria-label={legend.trim().length > 0 ? `${legend} ${error}` : `${ariaLabel} ${error}`}
				value={value || ''}
				type={inputType}
				onChange={onChange}
				required={isRequiredByDb || isRequired}
				onClick={onClick}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<span className="question-instructions">{instructions}</span>
			{error && error.length > 1 && (
				<p aria-label={`שגיאה לשדה ${legend} ${error}`} className="error">
					{error}
				</p>
			)}
			{!osekState && forms.businessDetails.osek?.length === 9 && (
				<p aria-label={`הערה לשדה ${legend} תעודת זהות אינה תקנית`} className="error">
					תעודת זהות אינה תקנית
				</p>
			)}
		</fieldset>
	);
}
