import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import './component.style.scss';
import FormContainer from '../FormContainer/FormContainer';
import NavArrows from '../NavArrows/NavArrows';
import CustomStepper from '../CustomStepper';
import Header from '../Header';
import ContactUsInfo from '../ContactUsInfo/ContactUsInfo';
import AppFooter from '../AppFooter';
import Accessibility from '../Accessability';
import { useStore } from '../../store';
import mainService from '../../api/MainService';

export default function App() {
	const location = window.location.pathname;
	const [logoData, setLogoData] = useState('');
	const [munName, setMunName] = useState('');
	const { businessDetailsValues } = useStore().businessDetailsFormContext;
	const { getDeliveryAddress, owners } = useStore().ownersFormContainerContext;
	const { updateDeliveryAddress, isLoading } = useStore().formsContainerContext;
	const { componentToShow, currentStep } = useStore().activeStepContext;

	const bufferToDataUrl = (buffer: number[], mimeType = 'image/jpeg') => {
		const base64 = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		return `data:${mimeType};base64,${base64}`;
	};
	useEffect(() => {
		const focusFirstInput = () => {
			const firstInput: any = document.querySelector('input, select, textarea, [tabindex]');
			if (firstInput) {
				firstInput.focus();
			}
		};
		focusFirstInput();
	}, [currentStep]);

	useEffect(() => {
		const getSocietyData = async () => {
			const currentMun = await mainService.getMun();
			sessionStorage.setItem('mun', JSON.stringify(currentMun));
			if (currentMun) {
				const image = await mainService.getImage(currentMun.logo_path);
				const imageAfterBuffer = bufferToDataUrl(image);
				setLogoData(imageAfterBuffer);
				setMunName(currentMun.descrip);
			}
		};
		getSocietyData();
		getRequiredColumnNames();
	}, []);

	useEffect(() => {
		const ownerThatSetHisAddressAsDeliveryAdress = getDeliveryAddress();
		if (ownerThatSetHisAddressAsDeliveryAdress !== undefined) {
			const { adress1, adress2, adress3 } = ownerThatSetHisAddressAsDeliveryAdress;
			updateDeliveryAddress(`${adress1} ${adress2} ${adress3.split('').reverse().join('')}`);
		} else {
			updateDeliveryAddress(`${businessDetailsValues.address3} ${businessDetailsValues.address2} ${businessDetailsValues.address1.split('').reverse().join('')}`);
		}
	}, [businessDetailsValues, owners]);

	useEffect(() => {
		if (!munName) return;
		let currentRouteTitle;

		switch (currentStep) {
			case 0:
				currentRouteTitle = ' שלב ראשון - פריטי עיסוק';
				break;
			case 1:
				currentRouteTitle = ' שלב שני -פרטי בעלים ומיופי כוח ';
				break;
			case 2:
				currentRouteTitle = ' שלב שלישי - פרטי העסק ';
				break;
			case 3:
				currentRouteTitle = 'שלב רביעי - פרטים נוספים על העסק ';
				break;
			case 4:
				currentRouteTitle = 'שלב חמישי - תצהירים ושיוך למסלולים ';
				break;
			case 5:
				currentRouteTitle = 'שלב שישי - מסמכים נדרשים וסיכום';
				break;
			default:
				currentRouteTitle = 'סיום תהליך הגשת הבקשה';
		}
		if (currentRouteTitle) {
			document.title = `${munName} - ${currentRouteTitle}`;
		} else {
			document.title = munName;
		}
	}, [currentStep, munName]);

	const getRequiredColumnNames = async () => {
		try {
			const res = await mainService.getStaticData("requiredDossierColumnNames");
			localStorage.setItem('requiredFields',res)
		} catch (err) {}
	};

	return (
		<>
			{location === '/accessibility-statement' ? (
				<>
					<Header mun_logo={logoData} mun_name={munName}></Header>
					<Accessibility />
					<AppFooter />
				</>
			) : logoData ? (
				<div className="app">
					<div className="fixed-contact-us-container">
						<ContactUsInfo />
					</div>
					{isLoading && (
						<div className="loader-overlay ">
							<div className="sending-form-loader flex column center" aria-label="שליחת בקשה בתהליך">
								<div className="loader-text-container flex column center">
									<span>בקשתך לרישיון עסק נשלחת ברגעים אלו ממש</span>
									<span> פעולה זו עשויה לקחת כמה רגעים</span>
								</div>
								<div className="loader-container">
									<CircularProgress size={80} aria-valuetext="טעינה" />
								</div>
							</div>
						</div>
					)}
					<div className="app__logo-container">
						<Header mun_logo={logoData} mun_name={munName}></Header>
					</div>
					<div className="main-container">
						<div className="stepper__container">
							<CustomStepper />
						</div>
						<FormContainer>{componentToShow}</FormContainer>
						{currentStep < 6 && <NavArrows />}
					</div>
					<AppFooter />
				</div>
			) : (
				<div className="loader"></div>
			)}
		</>
	);
}
